.popupTitle {
    margin-top: calc(var(--spacer) * 2px);
}


.formArea {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    margin: calc(var(--spacer) * 4px) 0px calc(var(--spacer) * 9px);
}

.column {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 15px;
}

.row {
    display: flex;
    flex-direction: row;
    width: 50%;
    gap: 15px;
}

.note {
    color: #676767;
    font-size: calc(var(--spacer) * 1.5px);
}

.afFooter {
    bottom: 0px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: flex-end;
    height: calc(var(--spacer) * 9px);
    background-color: var(--backgroundColor);
    padding: 0px calc(var(--spacer) * 1.5px);
    width: calc(100% - (var(--spacer) * 6px));
    border-top: 1px solid var(--pageBackground);
    float: right;
}