.option{
    display: flex;
    padding: 5px;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
}
.optionContent{
    padding: 5px 10px;
    border-top: 1px solid #0001;
}