.dropdownMenuDiv {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	float: left;
	min-width: 160px;
	padding: 5px 0;
	margin: 2px 0 0;
	list-style: none;
	background-color: #ffffff;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.2);
	*border-right-width: 2px;
	*border-bottom-width: 2px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	-webkit-background-clip: padding-box;
	-moz-background-clip: padding;
	background-clip: padding-box;
	padding-bottom: 0px;
	margin-bottom: 0px;
}

.dropdownMenu {
	padding-top: 0px;
    margin-top: 0px;
    border-top-width: 0px;
    border-radius: 2px;
    width: 100%;
	padding-left: 20px;
}

.dropdownMenu .divider {
	*width: 100%;
	height: 1px;
	margin: 9px 1px;
	*margin: -5px 0 5px;
	overflow: hidden;
	background-color: #e5e5e5;
	border-bottom: 1px solid #ffffff;
}

.dropdownMenu>li>a {
	padding: 3px 20px;
	clear: both;
	font-weight: normal;
	line-height: 20px;
	color: #333333;
	white-space: nowrap;
}

.dropdownMenu>li>a:hover,.dropdownMenu>li>a:focus
	{
	color: #ffffff;
	text-decoration: none;
	background-color: #17649A;
	
}


.dropdownMenu>.disabled>a,.dropdownMenu>.disabled>a:hover,.dropdownMenu>.disabled>a:focus
	{
	color: #999999;
}

.dropdownMenu>.disabled>a:hover,.dropdownMenu>.disabled>a:focus {
	text-decoration: none;
	cursor: default;
	background-color: transparent;
	background-image: none;
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

/* .disabled,.enabled{

} */

.dropdownOptions {
	font-size: 13px;
	color: #000000;
	overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    list-style-position: inside;
}


.dropdownMenuOptions {
	position: absolute;
    z-index: 100;
    background-color: white;
    transform: translate3d(-24px, 23px, 0px);
    will-change: transform;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 24%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: visible;
}

.dropdownMenuSpan {
    padding: 0px 15px;
    box-sizing: border-box;
    font-family: Saira;
    font-weight: 400;
    line-height: 35px;
    white-space: nowrap;
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
    text-align: left;
    font-size: 14px;
	align-items: center;
    display: flex;
}
.dropdownMenuSpan:hover {
	cursor: pointer;
	text-decoration: none;
	background-color: var(--activeBackgroundColor);
}

	

.dropdownMenuOptionsDiv {
	display: flex;
    flex-direction: column;
	overflow-y: scroll;
    max-height: 40vh;
	min-height: 160px;
}

.dropdownMenuOptionsInnerDiv {
	text-align: center;
}

.headImage{
	margin-right:0.75rem;
	height: 15px;
	width: 15px;
	float: right;
	margin-left: auto;
}

.popupContainer {
    margin-top: 24px;
    box-sizing: border-box;
    height: 400px;
    width: 100%;
    overflow: hidden;
	display: flex;
	justify-content: center;
}
  
.formContainer {
    width: 80%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    row-gap: 20px;
    height: 400px;
}

.popupitem {
	display: flex;
	margin-bottom: 5px;
	align-items: center;
}

.popupContainerTitle {
	height: 24px;
    font-size: var(--labelFontSize);
    font-style: normal;
	font-family: var(--fontName);
    margin-top: 16px;
    font-weight: normal;
	padding: 0px 6px 3px;
}

.varImg{
    margin-left: 8px;
    margin-right: 8px;
}

.popupitemContainer{
	padding-left: 20px;
}