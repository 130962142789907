

.testPanel {
    width: 400px;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    z-index: 1000;
    position: absolute;
    height: 100vh;
    right: 0px;
    top: 0px;
    background-color: white;
    overflow-y: scroll;
}

.testPanelBar {
    height: 40px;
    display: flex;
    background: #F0F2F7;
    align-items: center;
    padding-right: 24px;
    justify-content: space-between;
}


.testPanelTitle {
    color: #000000;
    height: 40px;
    display: flex;
    font-size: 13px;
    align-items: center;
    padding-left: 14px;
}

.testPanelCloseIcon {
    fill: #676767;
    width: 14px;
    cursor: pointer;
    height: 14px;
    margin-top: 6px;
}

.testPanelTextArea {
    margin: 8px;
    width: 95%;
    height: 75vh;
}

.testPanelFlex {
    display: flex;
    align-items: center;
    text-align: center;
}

.testul {
    padding-left: 0px;
}

.testli{
    padding-left: 16px;
    margin: 8px;
}

.testInputdiv {
    display: flex;
    align-items: center;
}

.testInputSpan {
    overflow: hidden;
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100px;
}


.testPanelPlayIcon {
    fill: #676767;
    width: 14px;
    cursor: pointer;
    height: 14px;
    margin: 8px;
}

.testbuttondiv {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 0;
}