.pagination-container {
  position: sticky;
  bottom: 0;
  left:0;
  width: 100%;
  border-top: 1px solid lightgray;
  background-color: white;
  height: 48px;
}

.pagination-inner-container {
  width: 400px;
  height: 42px;
  float: right;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.pagination-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  height: 30px;
  width: 60px;
  border-radius: 2px;
}

.button-value-container {
  display: flex;
  flex-direction: column;
}

.pagination-button-container {
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 70px;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 24%);
  border-radius: 2px;
}

.pagination-button-values {
  border: none;
  background-color: white;
  height: 30px;
  width: 60px;
}

.pagination-button-values:hover {
  background-color: lightgray;
}

.disable {
  color: lightgray;
  pointer-events: none; 
}

.pagination-rows {
  width: 100px;
  text-align: center;
}