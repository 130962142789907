.popupCont{
    display: flex;
    flex-direction: column;
}
.titleCont{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title{
    font-size: 32px;
    line-height: 48px;
    font-weight: 400;
    text-align: center;
}
.subTitle{
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    overflow-wrap: normal;
    width: 45%;
    text-align: center;
    margin: 10px 0 25px;
}
.tableCont{
    height: 328px;
    overflow: auto;
    /*padding: 10px;*/
    width: 100%;
    border-radius: 8px;
    background-color: #F0F2F7;
}
.formCont{
    margin: 5px;
    white-space: pre-wrap; 
}
.btnCont{
    padding: 14px 5px;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #0001;
}