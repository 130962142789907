.builder {
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - var(--spacer) * 8px);
    font-size: 14px;
  }

  .leftPanel {
    width: 20%;
    border-right: 1px solid lightgray;
    background: #ffffff;
    max-width: 312px;
    min-width: 312px;
    height: calc(100vh - 144px);
    margin:0 auto;
    overflow-x: hidden;
    overflow-y: scroll;
    font-weight: normal;
    font-style: normal;
    padding-bottom: 0px;
  }
  
  .canvas {
    width: 60%;
    background: #F0F2F7;
  }
  
  .gridInnerCon {
    width: 95%;
    overflow: scroll;
  }
  
  .properties {
    width: 100%;
    height: 100%;
    height: calc(100vh - 112px);
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .propertiesHead {
    width: 20%;
  }
  .variables{
    background-color: white;
    min-height: 100px;
  }

  .flex{
    display:flex;
    flex-direction: column;
    background-color: white;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }

  .parameter{
    height: 40px;
    display: flex;
    background: #F0F2F7;
    padding-top: 9px;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: space-between;
  }

  .label{
    padding: 0.5rem;
    border-bottom: 1px solid #cdd4e4;
  }

  .variableCard{
    width: fit-content;
    border: 1px solid rgba(0, 0, 0, 0.1);
    height: 24px;
    display: flex;
    font-size: 13px;
    background: #E2D0FF;
    align-items: center;
    border-radius: 16px;
    margin-bottom: 5px;
    margin-left:20px;
    margin-top:5px;
  }

  .icons{
    margin-bottom: 5px;
    margin-top:7px;
  }

  .var{
    overflow: hidden;
    max-width: 192px;
    white-space: nowrap;
    padding-left: 8px;
    padding-right: 8px;
    text-overflow: ellipsis;
  }

  .varImg{
    margin-left: 8px; 
  }

  .input{
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 100%;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: init;
    box-sizing: content-box;
    padding-left: 8px;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    background-color: unset;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
  }

  .input:focus{
    outline:none;
  }

  .searchIcon{
    display: flex;
    max-height: 2em;
    align-items: center;
    white-space: nowrap;
  }


  .searchDiv{
    display: flex;
    align-items: center;
    white-space: nowrap;
    box-shadow: unset;
    flex-direction: row;
  }