.basMain {
    width: 100%;
    height: 380px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.basHeading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.basContentMain {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 48px;
    justify-content: space-around;
}

.basContentMainVaried {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 18px;
    justify-content: space-around;
    margin-bottom: 20px;
}

.basContentWrap {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.basSubHeading {
    width: 56px;
    font-size: 16px;
    text-align: right;
    font-weight: 500;
    margin-right: 20px;
}

.valueText {
    font-size: 16px;
    font-weight: 400;
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;

    /* fontSize: '16px',
    fontWeight: '400',
    width: '70%',
    overflow: 'hidden',
    textOverflow: 'ellipsis' */
}

.copyBtn {
    border: none;
    background: none;
    cursor: pointer;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.copyBtn:hover {
    background-color: rgba(205, 212, 228, 0.15);
    border-radius: 50%;
}