
.titleCont{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title{
    font-weight: 200;
    text-align: center;
    color: #000000;
    font-size: 32px !important;
    line-height: 48px;
    background-color: #fff;
    width: 60%;
}
.subTitle{
    color: #000000;
    font-size: 14px;
    line-height: 24px;
    font-weight: 200;
    padding-top: 0px;
    background-color: #fff;
}

.formArea{
    width: 50%;
    margin: 0 auto;
    height: 280px;
}
.column {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.buttonHolder{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 24px;
    gap: 20px;
}
.metaCont{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.formCont{
    height: 390px;
    overflow: auto;
    padding: 10px;
}
.metaOptionCont{
    width: 60%;
    margin: 53px auto 5px;
    color: rgba(0, 0, 0, 0.75);
    font-weight: 500;
    font-size: 13px;
    box-shadow: 0px 1px 6px #0002;
    box-sizing: border-box;
}
.metaOption{
    box-shadow: 1px 0px 2px #0003;
    height: auto;
}
.option{
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #0001;
    display: flex;
    justify-content: space-between;
}
.buttonCont{
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #0001;
    padding: 12px 5px 2px;
}
.metaContent{
    min-height: 70px;
    padding: 10px;
}
.subOption{
    padding: 5px 5px 5px 5px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.subOptionContent{
    padding: 5px 8px 8px 30px;
    border-top: 1px solid #0001;
}
.subOptionCont{
    margin-left: 10px;
}
.systemOption{
    padding: 5px 5px 5px 5px;
}
.statusCont{
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.progressCont{
    width: 45%;
    text-align: center;
    margin: 0 auto;
}
.statusMsg{
    color: #24A148;
    margin: 10px 0px 30px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
}
.errorMsg{
    color: #ff0000;
}
.feedback{
    background-color: #F0F2F7;
    padding: 30px;
}
.ststusTextCont{
    width: 45%;
    margin: 0 auto;
    height: 265px;
}
.statusText{
    display: flex;
    column-gap: 15px;
    align-items: center;
    margin: 8px 30px;
    color:rgba(0, 0, 0, 0.87);
}
.disabledBtn{
    color: #fff;
    background-color: #EFEFEF;
    margin: 0 8px;
    padding: 4px 16px;
}