.btn-add-scheduler{
    display: flex;
    justify-content: center;
    align-items: center;
}

.scheduler-table{
    width: 80vw;
    margin: auto;
}

.scheduler-pagination{
    width: 80vw;
    margin: auto;
}