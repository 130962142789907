.mainUserLogs{
    height: 100vh;
    display: flex;
    overflow: hidden;
    overflow-y: auto;
    flex-direction: column;
}

.userLogsPage {
    background-color: #f0f2f7;
    min-height: 100vh;
}

.userLogsTitle{
    color: #000000;
    font-size: 40px;
    line-height: 48px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userLogsContent{
    padding-top: 0;
    padding-left: 12px;
    padding-right: 12px;
}

.userLogsTextWrap{
  display: flex;
  align-items: center;
}

.userLogsLabel{
    margin: 16px;
    display: flex;
    font-size: 13px;
    font-weight: 500;
}

.userTextLabel{
    margin: 16px;
    width: 100px;
    display: flex;
    font-size: 13px;
    font-weight: 500;
}

.userLogsValue{
    margin: 16px;
    display: inline-flex;
    font-size: 13px;
    word-break: break-word;
}

.footer {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgb(240, 242, 247);
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;
}

.logs-title {
    margin-block: calc(var(--spacer) * 2px);
}