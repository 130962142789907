.popupCont{
    display: flex;
    flex-direction: column;
}
.titleCont{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title{
    font-size: 32px;
    line-height: 48px;
    font-weight: 400;
    text-align: center;
}
.subTitle{
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    overflow-wrap: normal;
    width: 65%;
    text-align: center;
    margin: 10px 0 25px;
}
.tableCont{
    max-height: 250px;
    min-height: 250px;
    overflow: auto;
    margin: 0 auto;
    width: 75%;
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 8%) 0px 2px 6px;
}
.formCont{
    width: 75%;
    margin: 5px auto;
}
.btnCont{
    padding: 14px 5px;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #0001;
}
.optionCont{
    width: 100%;
    padding: 5px 5px;
    box-shadow: 0px 0px 2px #0003;
}
.metadataCont{
    overflow: auto;
    height: 390px;
    width: 50%;
    margin: 20Px auto 0;
}
.transportMsg{
    min-height: 390px;
    overflow: auto;
}