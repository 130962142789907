
.searchIcon{
    display: flex;
    max-height: 2em;
    align-items: center;
    white-space: nowrap;
}


.searchDiv{
    display: flex;
    align-items: center;
    white-space: nowrap;
    box-shadow: unset;
    flex-direction: row;
}

.varImg{
    margin-left: 8px; 
    margin-right: 8px;
}


.input{
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 100%;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: init;
    box-sizing: content-box;
    padding-left: 8px;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    background-color: unset;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
    border-radius: 2px;
}

.input:focus {
    outline: none;
}