.afFooter {
    bottom: 0px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: flex-end;
    height: calc(var(--spacer) * 9px);
    padding: 0px calc(var(--spacer) * 1.5px);
    width: calc(100% - (var(--spacer) * 6px));
    border-top: 1px solid var(--pageBackground);
}

.popupTitle {
    margin-top: calc(var(--spacer) * 2px);
}

.formArea {
    display: flex;
    justify-content: center;
    margin: calc(var(--spacer) * 5px) 0px;
}

.column {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.bosBox {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.boslist {
    height: 3rem;
    list-style: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding: 10px;
}

.select {
    background-color: var(--navbarBackgroundColor);
    color: var(--primaryTextColor);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    
}

.bosHeader {

    font-size: 3rem;
    text-align: center;
    margin-top: 20PX;


}

.inputStyle{
    padding: 6px;
    outline: none;
    background-color: var(--inputBackground);
    border: none;
}