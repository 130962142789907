
.linkcontainers{
	display: flex;
	margin-bottom: 5px;
	align-items: center;
	top: 115px;
	left: 320px;
	position: fixed;
	z-index: 100;
	color: rgba(0, 0, 0, 0.54);
}

.linkcontainer{
	display: flex;
	align-items: center;
	background-color: #fff;
	white-space: nowrap;
	cursor: pointer;
}

.linkSpan {
    padding: 0px 15px;
    box-sizing: border-box;
    font-family: Saira;
    font-weight: 500;
    line-height: 35px;
    white-space: nowrap;
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
    text-align: left;
    font-size: 18px;
	align-items: center;
    display: flex;
}

.linkSplitSpan {
    padding: 0px 8px;
    box-sizing: border-box;
    font-family: Saira;
    font-weight: 500;
    line-height: 35px;
    white-space: nowrap;
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
    font-size: 18px;
}

.varImg{
    margin-left: 8px; 
    margin-right: 8px;
	background-color: #fff;
	white-space: nowrap;
	cursor: pointer;
}