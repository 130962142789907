.accordion {
  max-width: 600px;
}

.accordiontitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.accordiontitle:hover {
  background-color: #F5F6FA;
}

.accordiontitle,
.accordioncontent {
  padding: 0.5rem;
  border-bottom: 1px solid #cdd4e4;
}

.iconhead{
  display:flex;
}

.accordioncontent {
  display:flex;
  flex-flow: column wrap;
  background-color: #FFFFFF;
  margin:0.25rem;
  align-items: baseline;
}

.blockImage{
   margin:0.5rem;
}

.headImage{
  margin-right:0.75rem;
}

@media screen and (max-width: 700px) {
  body {
    font-size: 13px;
  }

  .accordion {
    width: 90%;
  }
}